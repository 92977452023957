import { motion } from 'framer-motion'
import Header from '../components/Header'

export default function About() {
  return (
    <div className="min-h-screen bg-white dark:bg-[#0A0A0A]">
      <Header />
      
      <main className="container mx-auto px-4 pt-24 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="mx-auto max-w-4xl"
        >
          <div className="mb-8 flex items-center gap-4">
            <div className="h-3 w-3">
              <span className="inline-block h-full w-full rounded-full bg-green-500"></span>
            </div>
            <p className="text-gray-600 dark:text-gray-400">
              IT Officer at Airedale NHS Trust & Full-Stack Developer at Nyasalands
            </p>
          </div>

          <div className="space-y-6">
            <h1 className="text-5xl font-light leading-tight tracking-tight text-black dark:text-white sm:text-6xl">
              <span className="text-black dark:text-white">I am</span>{' '}
              <span className="bg-gradient-to-r from-purple-400 to-cyan-400 bg-clip-text text-transparent">
                exploring technology
              </span>{' '}
              <span className="text-gray-700 dark:text-gray-500">to improve</span>{' '}
              <span className="text-black dark:text-white">development</span>{' '}
              <span className="text-gray-700 dark:text-gray-500">by learning</span>{' '}
              <span className="bg-gradient-to-r from-orange-400 to-rose-400 bg-clip-text text-transparent">
                new skills
              </span>
            </h1>

            <div className="space-y-6 text-lg text-gray-600 dark:text-gray-400">
              <p className="leading-relaxed">
                I studied my <span className="bg-gradient-to-r from-purple-400 to-cyan-400 bg-clip-text text-transparent">
                bachelors degree in Computer Engineering in Kayseri, Turkey</span>. During my time there 
                I also went to study for a semester at <span className="bg-gradient-to-r from-purple-400 to-cyan-400 bg-clip-text text-transparent">
                Politechnika Bydgoska im. Jana i Jędrzeja Śniadeckich in Bydgoszcz, Poland</span> through the Erasmus program.
              </p>

              <p className="leading-relaxed">
              I love travelling, writting, gaming, listening to music and watching tech reviews. I enjoy hanging out with friends and interacting 
              with my immediate community. <span className="bg-gradient-to-r from-purple-400 to-cyan-400 bg-clip-text text-transparent">
              I like this phrase I'm a simple person, city life doesn't move me {' '}
              </span>
              so much it has almost defined me as person since 2017 but i relate to the Bible verse from Jeremiah 10:23 more which says
              <span className="bg-gradient-to-r from-orange-400 to-rose-400 bg-clip-text text-transparent">
              {' '} 'O LORD, I know that the path of [life of] a man is not in 
                himself; It is not within [the limited ability of] man [even one at his best] to choose and direct his steps [in life].'
              </span> 
              </p>

              <div className="pt-4">
                <h2 className="mb-4 text-2xl font-light text-black dark:text-white">
                  Currently working on
                </h2>
                <ul className="list-inside list-disc space-y-2">
                  <li>
                    <a href="https://www.nyasalands.com/" target="_blank" rel="noopener noreferrer" className="bg-gradient-to-r from-purple-400 to-cyan-400 bg-clip-text text-transparent">
                      Nyasalands
                    </a>
                  </li>
                  <li>iOS & Swift Development</li>
                  <li>
                    <a href="https://www.undefiledreligion.family/" target="_blank" rel="noopener noreferrer" className="bg-gradient-to-r from-purple-400 to-cyan-400 bg-clip-text text-transparent">
                      Undefiled Religion
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="flex gap-4 pt-8">
              <motion.a
                href="https://www.linkedin.com/in/patrick-sibale?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
                target="_blank"
                rel="noopener noreferrer"
                className="rounded-full bg-gray-100 dark:bg-[#1A1A1A] px-6 py-2 text-sm text-gray-900 dark:text-white transition-colors hover:bg-gray-200 dark:hover:bg-[#252525]"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                LinkedIn
              </motion.a>
            </div>
          </div>
        </motion.div>
      </main>
    </div>
  )
}