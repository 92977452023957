import { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'

interface Point {
  x: number
  y: number
  initialX: number
  initialY: number
  id: number
}

const MagneticPoints = () => {
  const [points, setPoints] = useState<Point[]>([])
  const containerRef = useRef<HTMLDivElement>(null)
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 })

  useEffect(() => {
    if (!containerRef.current) return

    const container = containerRef.current
    const rect = container.getBoundingClientRect()
    const newPoints: Point[] = []

    // Create points distributed across the container
    for (let i = 0; i < 100; i++) {
      const x = Math.random() * (rect.width - 4) // Subtract point width to keep within bounds
      const y = Math.random() * (rect.height - 4) // Subtract point height to keep within bounds
      newPoints.push({
        x,
        y,
        initialX: x,
        initialY: y,
        id: i,
      })
    }

    setPoints(newPoints)
  }, [])

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!containerRef.current) return

    const rect = containerRef.current.getBoundingClientRect()
    const x = e.clientX - rect.left
    const y = e.clientY - rect.top
    setMousePos({ x, y })

    setPoints((currentPoints) =>
      currentPoints.map((point) => {
        const dx = x - point.initialX
        const dy = y - point.initialY
        const distance = Math.sqrt(dx * dx + dy * dy)
        const maxDistance = 150
        const repulsionStrength = 70

        if (distance < maxDistance) {
          const force = (1 - distance / maxDistance) * repulsionStrength
          return {
            ...point,
            x: point.initialX - (dx / distance) * force,
            y: point.initialY - (dy / distance) * force,
          }
        }

        return {
          ...point,
          x: point.initialX + (point.x - point.initialX) * 0.9,
          y: point.initialY + (point.y - point.initialY) * 0.9,
        }
      })
    )
  }

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!containerRef.current) return

    const rect = containerRef.current.getBoundingClientRect()
    const touch = e.touches[0]
    const x = touch.clientX - rect.left
    const y = touch.clientY - rect.top
    setMousePos({ x, y })

    handleMouseMove({ clientX: touch.clientX, clientY: touch.clientY } as any)
  }

  return (
    <div
      ref={containerRef}
      onMouseMove={handleMouseMove}
      onTouchMove={handleTouchMove}
      className="relative h-32 w-full overflow-hidden"
    >
      {points.map((point) => (
        <motion.div
          key={point.id}
          animate={{
            x: point.x,
            y: point.y,
          }}
          transition={{
            type: "spring",
            stiffness: 100,
            damping: 10,
          }}
          className="absolute h-2 w-2 rounded-full bg-gray-800 dark:bg-gray-200"
          style={{
            left: 0,
            top: 0,
          }}
        />
      ))}
    </div>
  )
}

export default MagneticPoints