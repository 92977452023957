import { useEffect, useState } from 'react'

export default function Footer() {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const contactSection = document.querySelector('section:last-of-type') // Gets the last section (Contact)
      if (contactSection) {
        const rect = contactSection.getBoundingClientRect()
        // Show footer slightly before reaching the bottom of contact section
        setIsVisible(rect.bottom <= window.innerHeight + 2)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <footer 
      className={`fixed bottom-0 left-0 w-full transform transition-all duration-500 ease-out ${
        isVisible ? 'translate-y-0' : 'translate-y-full'
      } bg-gradient-to-r from-zinc-900 to-zinc-800 backdrop-blur-md border-t border-zinc-800`}
    >
      <div className="mx-auto max-w-5xl px-4 py-8">
        <div className="flex flex-col items-center justify-between space-y-4 sm:flex-row sm:space-y-0">
          <div className="flex space-x-2 text-zinc-400 text-base">
            <div>Patrick Sibale</div>
            <div>{` • `}</div>
            <div>{`© ${new Date().getFullYear()}`}</div>
          </div>
          <div className="flex space-x-6 text-zinc-400">
            <a 
              href="https://www.linkedin.com/in/patrick-sibale?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" 
              className="hover:text-zinc-200 transition-colors text-base"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
            <a 
              href="https://twitter.com/nyasalands" 
              className="hover:text-zinc-200 transition-colors text-base"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}