export interface Project {
  id: number;
  title: string;
  description: string;
  imageUrl: string;
}

export const projects: Project[] = [
  {
    id: 1,
    title: "Brydg Capital London",
    description: "Development of components",
    imageUrl: "/images/brydg.gif"
  },
  {
    id: 2,
    title: "Nyasalands",
    description: "Design & Full-Stack Development",
    imageUrl: "/images/nyasalandsfinal.gif"
  },
  {
    id: 3,
    title: "Ninjo.io",
    description: "Front-End Development",
    imageUrl: "/images/ninjo.png"
  },
  {
    id: 4,
    title: "Undefiled Religion",
    description: "Design and Development",
    imageUrl: "/images/und.gif"
  },
];