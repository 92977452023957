import HomeLayout from "@/layouts/HomeLayout"
import Header from "@/components/Header"
import { motion } from "framer-motion"
import { Button } from "@/components/ui/button"
import { Textarea } from "@/components/ui/textarea"
import { useState } from "react"
import { toast } from "sonner"
import { Mail, ArrowRight, Sparkles } from "lucide-react"

export default function RequestQuote() {
  const [message, setMessage] = useState("")
  const EMAIL = "me@patrickcbale.nl"
  
  const handleSendEmail = () => {
    if (!message.trim()) {
      toast.error("Please write a message before sending")
      return
    }

    const defaultMessage = `
Project Details:
${message}
`
    const subject = encodeURIComponent("Website Project Inquiry")
    const body = encodeURIComponent(defaultMessage)
    const mailtoLink = `mailto:${EMAIL}?subject=${subject}&body=${body}`
    
    window.location.href = mailtoLink
    toast.success("Preparing your email...")
  }

  return (
    <HomeLayout>
      <Header />
      <div className="bg-background min-h-screen transition-colors duration-300">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="container max-w-3xl mx-auto px-4 py-12"
        >
          <div className="text-center mb-8">
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="inline-flex items-center gap-2 px-4 py-1.5 rounded-full bg-primary/10 text-primary mb-4"
            >
              <Sparkles className="w-4 h-4" />
              <span className="text-sm font-medium">Let's Discuss Your Vision</span>
            </motion.div>
            
            <motion.h1 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
              className="text-4xl md:text-5xl font-bold mb-4 text-foreground"
            >
              Request a Website Consultation
            </motion.h1>
            <motion.p 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.4 }}
              className="text-muted-foreground text-lg max-w-2xl mx-auto"
            >
              I'm excited to learn about your project. Share the details below and let's create something exceptional together.
            </motion.p>
          </div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
            className="space-y-8"
          >
            <div className="bg-card rounded-2xl p-6 shadow-lg border border-border">
              <Textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder={`Please share details about your project. Here are some helpful points to consider:

• Type of website (e.g., business, portfolio, e-commerce)
• Key features you'd like to include
• Design preferences or inspiration
• Target audience
• Approximate budget range
• Desired timeline
• Any specific technical requirements
• Content management needs

The more details you provide, the better I can understand your vision!`}
                className="min-h-[300px] text-base bg-background border-input focus:border-primary mb-4 placeholder:text-muted-foreground"
              />
              
              <div className="flex flex-col sm:flex-row gap-4 items-center justify-between">
                <motion.div
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="w-full sm:w-auto"
                >
                  <Button 
                    onClick={handleSendEmail}
                    className="w-full sm:w-auto group bg-primary hover:bg-primary/90 text-primary-foreground"
                  >
                    <Mail className="w-4 h-4 mr-2" />
                    Send Message
                    <ArrowRight className="w-4 h-4 ml-2 opacity-0 group-hover:opacity-100 transition-opacity" />
                  </Button>
                </motion.div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </HomeLayout>
  )
}