import { Mail, Code2, Sparkles } from "lucide-react"
import { Button } from "./ui/button"
import { motion } from "framer-motion"
import { Link } from "react-router-dom"

export default function ContactSection() {
  const handleEmailClick = () => {
    window.location.href = "mailto:me@patrickcbale.nl"
  }

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  }

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  }

  return (
    <section className="w-full py-24 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-r from-zinc-900 to-zinc-700 animate-gradient" />
      <motion.div 
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        className="relative z-10 max-w-4xl mx-auto px-4 text-center flex flex-col items-center"
      >
        <motion.div variants={itemVariants} className="mb-2">
          <span className="inline-flex items-center gap-2 px-3 py-1 text-sm font-medium rounded-full bg-white/10 text-white/90">
            <Sparkles className="w-4 h-4" />
            Available for New Projects
          </span>
        </motion.div>
        
        <motion.h2 
          variants={itemVariants}
          className="text-4xl md:text-5xl font-bold mb-6 text-white"
        >
          Bringing Your Vision to Life, One Website at a Time
        </motion.h2>
        
        <motion.p 
          variants={itemVariants}
          className="text-white/90 mb-8 text-lg max-w-2xl"
        >
          I specialize in building modern, responsive websites that help businesses grow. 
          From landing pages to full-scale, full-stack web applications, I transform ideas into elegant, 
          extraordinary digital experiences
        </motion.p>

        <motion.div 
          variants={itemVariants}
          className="flex flex-col sm:flex-row gap-4 items-center justify-center"
        >
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ type: "spring", stiffness: 400, damping: 17 }}
          >
            <Button
              onClick={handleEmailClick}
              size="lg"
              className="bg-white text-gray-800 hover:bg-white/90 transition-all duration-200 flex items-center gap-2 shadow-lg hover:shadow-xl group relative overflow-hidden"
            >
              <Mail className="w-4 h-4 relative z-10" />
              <span className="relative z-10">me@patrickcbale.nl</span>
              <div className="absolute inset-0 bg-gradient-to-r from-white via-primary-100 to-white opacity-0 group-hover:opacity-100 transition-opacity duration-300 -z-0" />
            </Button>
          </motion.div>

          <Link to="/request-quote">
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              transition={{ type: "spring", stiffness: 400, damping: 17 }}
            >
              <Button
                size="lg"
                variant="outline"
                className="bg-transparent text-white border-white hover:bg-white/10 transition-all duration-200"
              >
                Request a Quote
              </Button>
            </motion.div>
          </Link>

          <motion.div
            initial={{ opacity: 0.6 }}
            whileHover={{ opacity: 1 }}
            className="flex items-center gap-2 text-white/80"
          >
            <Code2 className="w-5 h-5" />
          </motion.div>
        </motion.div>
      </motion.div>
    </section>
  )
}