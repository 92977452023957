import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { locationData } from '@/data/career';

interface CareerMapProps {
  mapStyle: 'light' | 'dark';
}

const CareerMap = ({ mapStyle }: CareerMapProps) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const markersRef = useRef<mapboxgl.Marker[]>([]);

  useEffect(() => {
    if (!mapContainer.current || mapRef.current) return;

    mapboxgl.accessToken = 'pk.eyJ1IjoidHdhbWJpZSIsImEiOiJjbTR4ZTlndjkwam9jMmpwN3V1cjVmNzJ5In0.3JvEtwOUx17j6NNwSDqiLg';
    
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: `mapbox://styles/mapbox/${mapStyle}-v11`,
      center: [-0.1276, 51.4999],
      zoom: 16,
      pitch: 60,
      bearing: 20,
      antialias: true
    });

    mapRef.current = map;

    map.addControl(new mapboxgl.NavigationControl(), 'top-right');

    const getMarkerColor = (type: string) => {
      switch (type) {
        case 'career':
          return '#004e92';
        case 'education':
          return '#2F4F4F';
        case 'travel':
          return '#FFA07A';
        default:
          return '#000000';
      }
    };

    const getLocationMessage = (type: string) => {
      switch (type) {
        case 'career':
          return 'Patrick worked here.';
        case 'education':
          return 'Patrick studied here.';
        case 'travel':
          return 'Patrick traveled here.';
        default:
          return '';
      }
    };

    // Add 3D terrain and sky
    map.on('load', () => {
      // Add terrain source
      map.addSource('mapbox-dem', {
        'type': 'raster-dem',
        'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
        'tileSize': 512,
        'maxzoom': 14
      });

      // Add terrain layer
      map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });

      // Add sky layer
      map.addLayer({
        'id': 'sky',
        'type': 'sky',
        'paint': {
          'sky-type': 'atmosphere',
          'sky-atmosphere-sun': [0.0, 90.0],
          'sky-atmosphere-sun-intensity': 15
        }
      });

      // Add 3D building layer
      const layers = map.getStyle().layers;
      const labelLayerId = layers.find(
        (layer) => layer.type === 'symbol' && layer.layout?.['text-field']
      )?.id;

      map.addLayer(
        {
          'id': 'add-3d-buildings',
          'source': 'composite',
          'source-layer': 'building',
          'filter': ['==', 'extrude', 'true'],
          'type': 'fill-extrusion',
          'minzoom': 15,
          'paint': {
            'fill-extrusion-color': '#aaa',
            'fill-extrusion-height': [
              'interpolate',
              ['linear'],
              ['zoom'],
              15,
              0,
              15.05,
              ['get', 'height']
            ],
            'fill-extrusion-base': [
              'interpolate',
              ['linear'],
              ['zoom'],
              15,
              0,
              15.05,
              ['get', 'min_height']
            ],
            'fill-extrusion-opacity': 0.6
          }
        },
        labelLayerId
      );

      // Setup markers
      setupMarkers();

      // Start the animation sequence after 3 seconds
      setTimeout(() => {
        map.flyTo({
          center: [15, 50],
          zoom: 4,
          pitch: 45,
          bearing: -17.6,
          duration: 6000,
          essential: true
        });
      }, 3000);
    });

    const setupMarkers = () => {
      markersRef.current.forEach(marker => marker.remove());
      markersRef.current = [];

      locationData.forEach((point) => {
        const el = document.createElement('div');
        el.className = 'marker';
        el.style.width = '15px';
        el.style.height = '15px';
        el.style.borderRadius = '50%';
        el.style.backgroundColor = getMarkerColor(point.type);
        el.style.border = '2px solid white';
        el.style.boxShadow = '0 0 4px rgba(0,0,0,0.3)';
        el.style.cursor = 'pointer';

        const popup = new mapboxgl.Popup({
          offset: 8,
          closeButton: false,
          closeOnClick: true,
          maxWidth: 'none',
          className: 'custom-popup'
        });

        popup.setHTML(`
          <div class="px-2 py-1">
            <h3 class="text-[10px] font-medium leading-tight">${point.title}</h3>
            <p class="text-[8px] text-gray-500 leading-tight">${getLocationMessage(point.type)}</p>
          </div>
        `);

        const marker = new mapboxgl.Marker(el)
          .setLngLat([point.longitude, point.latitude])
          .setPopup(popup)
          .addTo(map);

        markersRef.current.push(marker);
      });
    };

    return () => {
      markersRef.current.forEach(marker => marker.remove());
      markersRef.current = [];
      
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [mapStyle]);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setStyle(`mapbox://styles/mapbox/${mapStyle}-v11`);
    }
  }, [mapStyle]);

  return (
    <div className="w-full h-[500px] rounded-lg overflow-hidden shadow-lg">
      <div ref={mapContainer} className="w-full h-full" />
    </div>
  );
};

export default CareerMap;