export interface LocationPoint {
  title: string;
  date: string;
  description: string;
  location: string;
  latitude: number;
  longitude: number;
  type: 'career' | 'education' | 'travel';
}

export const locationData: LocationPoint[] = [
  // Education
  {
    title: "B.S. in Computer Engineering",
    date: "Sept. 2016 – July 2021",
    description: "Studied core computer engineering principles at Abdullah Gul University.",
    location: "Kayseri, Turkey",
    latitude: 38.7342,
    longitude: 35.4679,
    type: 'education'
  },
  {
    title: "Erasmus Program",
    date: "Sept. 2017 – Feb. 2018",
    description: "Exchange program focused on advanced topics in computer engineering.",
    location: "Bydgoszcz, Poland",
    latitude: 53.1210,
    longitude: 18.0109,
    type: 'education'
  },
  // Career
  {
    title: "IT Officer",
    date: "Feb. 2023 – Current",
    description: "Manage IT assets, resolve issues, and maintain data security.",
    location: "Steeton, United Kingdom",
    latitude: 53.8948,
    longitude: -1.9692,
    type: 'career'
  },
  {
    title: "Admin Assistant",
    date: "July 2022 – Dec. 2022",
    description: "Developed workflows, trained staff, and managed a team of 60.",
    location: "Leeds, United Kingdom",
    latitude: 53.8008,
    longitude: -1.5491,
    type: 'career'
  },
  {
    title: "Front-End Developer",
    date: "Feb. 2021 – Jun. 2021",
    description: "Developed front-end solutions and UI designs for Brydg Capital.",
    location: "London, United Kingdom",
    latitude: 51.5074,
    longitude: -0.1278,
    type: 'career'
  },
  {
    title: "Robotics Engineer",
    date: "June 2019 – Sept. 2019",
    description: "Programmed bots and implemented AI in robotics projects.",
    location: "Coimbra, Portugal",
    latitude: 40.2033,
    longitude: -8.4103,
    type: 'career'
  },
  {
    title: "IT Support Specialist",
    date: "No Date",
    description: "Home.",
    location: "Blantyre, Malawi",
    latitude: -15.7861,
    longitude: 35.0058,
    type: 'career'
  },
  // Travel Locations
  {
    title: "Barcelona",
    date: "",
    description: "Travel destination",
    location: "Barcelona, Spain",
    latitude: 41.3851,
    longitude: 2.1734,
    type: 'travel'
  },
  {
    title: "Madrid",
    date: "",
    description: "Travel destination",
    location: "Madrid, Spain",
    latitude: 40.4168,
    longitude: -3.7038,
    type: 'travel'
  },
  {
    title: "Lusaka",
    date: "",
    description: "Travel destination",
    location: "Lusaka, Zambia",
    latitude: -15.3875,
    longitude: 28.3228,
    type: 'travel'
  },
  {
    title: "Cape Town",
    date: "",
    description: "Travel destination",
    location: "Cape Town, South Africa",
    latitude: -33.9249,
    longitude: 18.4241,
    type: 'travel'
  },
  {
    title: "Dar es Salaam",
    date: "",
    description: "Travel destination",
    location: "Dar es Salaam, Tanzania",
    latitude: -6.7924,
    longitude: 39.2083,
    type: 'travel'
  },
  {
    title: "Dubai",
    date: "",
    description: "Travel destination",
    location: "Dubai, UAE",
    latitude: 25.2048,
    longitude: 55.2708,
    type: 'travel'
  },
  {
    title: "Prague",
    date: "",
    description: "Travel destination",
    location: "Prague, Czech Republic",
    latitude: 50.0755,
    longitude: 14.4378,
    type: 'travel'
  },
  {
    title: "Vienna",
    date: "",
    description: "Travel destination",
    location: "Vienna, Austria",
    latitude: 48.2082,
    longitude: 16.3738,
    type: 'travel'
  },
  {
    title: "Munich",
    date: "",
    description: "Travel destination",
    location: "Munich, Germany",
    latitude: 48.1351,
    longitude: 11.5820,
    type: 'travel'
  },
  {
    title: "Oslo",
    date: "",
    description: "Travel destination",
    location: "Oslo, Norway",
    latitude: 59.9139,
    longitude: 10.7522,
    type: 'travel'
  },
  {
    title: "Budapest",
    date: "",
    description: "Travel destination",
    location: "Budapest, Hungary",
    latitude: 47.4979,
    longitude: 19.0402,
    type: 'travel'
  },
  {
    title: "Paris",
    date: "",
    description: "Travel destination",
    location: "Paris, France",
    latitude: 48.8566,
    longitude: 2.3522,
    type: 'travel'
  }
];