import { Analytics } from '@vercel/analytics/react'
import Header from '../components/Header'
import CareerMap from '@/components/CareerMap'
import MagneticPoints from '../components/MagneticPoints'
import Footer from '../components/Footer'
import HomeLayout from '../layouts/HomeLayout'
import SectionContainer from '../components/SectionContainer'
import { useState } from 'react'
import { Button } from '@/components/ui/button'
import { Moon, Sun } from 'lucide-react'

export default function Projects() {
  const [mapStyle, setMapStyle] = useState<'light' | 'dark'>('dark')

  const toggleMapStyle = () => {
    setMapStyle(prev => prev === 'light' ? 'dark' : 'light')
  }

  return (
    <HomeLayout>
      <Header />
      <SectionContainer>
        <div className="space-y-16 py-16">
          <div>
            <div className="flex flex-col space-y-4 mb-12">
              <h1 className="text-5xl font-playfair mb-4">
                <span className="text-primary-500 mr-2">#1</span>
                Career & Travel 3D Map
              </h1>
              <p className="text-muted-foreground max-w-3xl">
                An interactive 3D visualization of my professional journey, educational background, and travel experiences across the globe. 
                The map includes 3D terrain visualization, a sky layer for atmospheric effects, and 3D buildings that appear when zoomed in close to cities. 
                The map is tilted by default (45 degrees) to showcase the 3D features better. You can interact with markers and popups to explore locations, 
                seeing elevation changes and building heights while exploring different places.
              </p>
              <div className="flex items-center gap-2">
                <div className="flex items-center gap-2">
                  <div className="w-3 h-3 rounded-full bg-primary-500 border-2 border-white shadow"></div>
                  <span className="text-sm">Career</span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-3 h-3 rounded-full bg-forest border-2 border-white shadow"></div>
                  <span className="text-sm">Education</span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-3 h-3 rounded-full bg-sunset border-2 border-white shadow"></div>
                  <span className="text-sm">Travel</span>
                </div>
                <Button
                  variant="outline"
                  size="icon"
                  className="ml-auto"
                  onClick={toggleMapStyle}
                  aria-label="Toggle map style"
                >
                  {mapStyle === 'dark' ? (
                    <Sun className="h-4 w-4" />
                  ) : (
                    <Moon className="h-4 w-4" />
                  )}
                </Button>
              </div>
            </div>
            <CareerMap mapStyle={mapStyle} />
          </div>

          <div>
            <h2 className="text-5xl font-playfair mb-4">
              <span className="text-primary-500 mr-2">#2</span>
              Magnetic Points
            </h2>
            <p className="text-muted-foreground mb-8 max-w-3xl">
              A mesmerizing grid of points that react to cursor proximity, creating a magnetic repulsion effect. Each point smoothly animates away from the cursor, demonstrating advanced mouse interaction and physics-based animations.
            </p>
            <div className="border rounded-lg overflow-hidden bg-background">
              <MagneticPoints />
            </div>
          </div>
        </div>
      </SectionContainer>
      <Footer />
      <Analytics />
    </HomeLayout>
  )
}