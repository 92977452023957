import { Analytics } from '@vercel/analytics/react'
import Header from '../components/Header'
import Hero from '../components/Hero'
import Intro from '../components/Intro/Intro'
import ProjectGallery from '../components/ProjectGallery/ProjectGallery'
import ContactSection from '../components/ContactSection'
import SectionContainer from '../components/SectionContainer'
import HomeLayout from '../layouts/HomeLayout'
import Footer from '../components/Footer'

export default function Home() {
  return (
    <HomeLayout>
      <Header />
      <SectionContainer>
        <div></div>
      </SectionContainer>
      <Hero />
      <Intro />
      <ProjectGallery />
      <ContactSection />
      <Footer />
      <Analytics />
    </HomeLayout>
  )
}